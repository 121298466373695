body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  letter-spacing: 0.12em;
  font-family: "TGR Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* It needs to be applied again to buttons for some reason  */
button,
input {
  letter-spacing: 0.12em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
